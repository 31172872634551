/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css';
import './src/styles/mobile.css';
import './src/scripts/slideshow.js';
import './src/styles/slideshow.css';
