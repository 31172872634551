window.addEventListener('load', () => {
  let prevLocation = null;
  let initialized = false;
  let slideIndex = 0;
  let i = 0;

  const showSlides = () => {
    let slides = document.getElementsByClassName('mySlides');
    let dots = document.getElementsByClassName('dot');
    if (slides.length > 1) {
      for (i = 0; i < slides.length; i++) {
        slides[i].style.left = '-1000%';
        slides[i].style.position = 'absolute';
      }
      slideIndex++;
      if (slideIndex > slides.length) {
        slideIndex = 1;
      }
      if (dots.length > 1) {
        for (i = 0; i < dots.length; i++) {
          dots[i].className = dots[i].className.replace(' active', '');
        }
        dots[slideIndex - 1].className += ' active';
      }
      slides[slideIndex - 1].style.left = '0%';
      slides[slideIndex - 1].style.position = 'relative';
      setTimeout(showSlides, 6500); // Change image every 2 seconds
    }
  };

  setInterval(() => {
    //initialization routine
    //when target element exist, initialize
    if (prevLocation !== window.location.href) {
      console.log('page location:', window.location.href);
      prevLocation = window.location.href;

      let slides = document.getElementsByClassName('mySlides');
      if (slides.length > 1) {
        if (initialized === false) {
          initialized = true;
          slideIndex = 0;
          i = 0;
          showSlides();
        }
      } else {
        initialized = false;
      }
    }
  }, 500);
});
